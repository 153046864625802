import Repository from './repository'

const resource = '/dashboard'

export default {
  getListaVendedorVendas (loja, ano, mes, vendedor) {
    return Repository.get(`${resource}/vendasvendedor/${loja}/${ano}/${mes}/${vendedor}`)
  },
  getListaVendasAno (loja, ano) {
    return Repository.get(`${resource}/vendasano/${loja}/${ano}`)
  },
  getListaTopVendedorVendasAno (loja, ano) {
    return Repository.get(`${resource}/vendedortopano/${loja}/${ano}`)
  },
  getListaMetaVendedor (loja, ano, mes) {
    return Repository.get(`${resource}/metasvendedor?loja=${loja}&mes=${mes}&ano=${ano}`)
  },
  getListaTicketmedio (loja, ano, mes) {
    return Repository.get(`${resource}/ticketmedio?loja=${loja}&mes=${mes}&ano=${ano}`)
  },
  Nps () {
    return Repository.get(`${resource}/nps`)
  },
}
